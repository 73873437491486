import React, { useEffect, useState } from 'react';
import _fetch from '../../config/api';
import { ReStakeContractAddress, StakeContractAddress, api_url } from '../../config/Config';
import toasted from '../../config/toast';
import Loader from '../../Layout/Loader';
import LoaderDyn from '../../Layout/LoaderDyn';
import Web3 from 'web3';

const RestakeCoin = (props) => {

  const [amount, setAmount] = useState("");
  const [getSopnserWallet, setgetSopnserWallet] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [gethash, setGethash] = useState('');
  const [balance, setBalance] = useState(null);
  const tokenAddress = '0x493361D6164093936c86Dcb35Ad03b4C0D032076';
  const userAddress = props.user_wallet;
  const abi = props.VistaContractAbi;
  const [approveLoader, setapproveLoader] = useState('0');
  const [changeSection, setchangeSection] = useState(false);
  const [activeLogin, setactiveLogin] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);
  const [sponserAddress, SetsponserAddress] = useState();

  const approveAndSendTokens = async () => {
    if (amount >= 50) {

      setbtnDisable(true);
      const web3 = props?.maskData?.web3;
      const account = props?.maskData?.address;
      const tokenContract = props?.maskData?.tokenContract;
      const StakeContract = props?.maskData?.StakeContract;
      const ReStakeContract = props?.maskData?.ReStakeContract;

      if (web3 && account && StakeContract) {
        setapproveLoader('1')
        let approvalAmount = amount / props?.coinPrice;

        approvalAmount = web3.utils.toWei(`${approvalAmount}`, 'ether'); // Replace with the desired approval amount
        try {
          const _approvalGas = await ReStakeContract.methods.approve(StakeContractAddress, approvalAmount).estimateGas({
            from: account,
          });


          const approvalGas = await parseInt(_approvalGas.toString().replace("n", ""));
          const _gasPrice = await web3.eth.getGasPrice();
          const gasPrice = await parseInt(_gasPrice.toString().replace("n", ""));
          // console.log('gasPrice sadasd', gasPrice, approvalAmount);
          // console.log('approvalGas', approvalGas);

          const gasPriceGwei = gasPrice;
          const gasPriceWei = Math.floor(gasPriceGwei);

          const approvalClear = await ReStakeContract.methods.approve(StakeContractAddress, approvalAmount).send({
            from: account,
            gasPrice: gasPriceWei.toString(),
            gasLimit: approvalGas
          }).once('transactionHash', function (hash) {
            //   console.log('approval hash: ', hash);

          }).on('error', function (error) {
            //   console.log('\n[ERROR]\n\n' + error
            //     .message);
            let msg = error.message.split(":");
            // toastr.error("Error " + error.code +
            //     " : " + msg[1]);
          }).then(async function (receipt) {
            setapproveLoader('2')
            setchangeSection(false)

            const getEstimatedGas = await StakeContract.methods.reStake(approvalAmount, props?.user_wallet).estimateGas({ from: account });
            const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));
            // const gasPrice = await web3.eth.getGasPrice();

            const _gasPriceGwei = gasPrice;
            const _gasPriceWei = Math.floor(_gasPriceGwei * 1.3);
            await StakeContract.methods.reStake(approvalAmount.toString(), props?.user_wallet).send({
              from: account,
              gasPrice: _gasPriceWei.toString(),
              gasLimit: estimatedGas
            }).once('transactionHash', function (hash) {
              // console.log('transactionHash hash: ', hash);
              setbtnDisable(true)

              // console.log(hash);
              // setGethash(hash)
              setchangeSection(true)
            }).on('error', function (error) {
              // console.log('error', error);

              // console.log('\n[ERROR]\n\n' + error
              //   .message);
              let msg = error.message.split(":");
              // toastr.error("Error " + error.code +
              //     " : " + msg[1]);
            }).then(async function (receipt) {

              // call node apiLOG
              StakeAmount(receipt.transactionHash)

              setactiveLogin(true)
              // setGethash(receipt.transactionHash)


            })
          })

          // console.log('Tokens approved and sent successfully');
        } catch (error) {
          // console.error('Error approving and sending tokens:', error);
          toasted.error(error?.data?.message);
          setbtnDisable(false)
          setapproveLoader('3')

        }
      }
    } else {
      toasted.error('Please enter amount minimum $50');

    }
  };

  // const fetchBalance = async () => {

  //   try {
  //     const web3 = new Web3('https://bsc-dataseed.binance.org/');
  //     const tokenContract = new web3.eth.Contract(abi, tokenAddress);
  //     const balance = await tokenContract.methods.balanceOf(userAddress).call();
  //     const balanceInEther = web3.utils.fromWei(balance, 'ether');

  //     // Condition to check if balance is greater than 5
  //     if (parseFloat(balanceInEther) > 5) {
  //       // console.log('Sufficient Balance');

  //     } else {
  //       // console.log('InSufficient Balance');
  //     }

  //     setBalance(balanceInEther);
  //   } catch (err) {
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const StakeAmount = async (hash) => {

    let data = {
      "username": props.userName,
      "amount": parseInt(amount),
      "investCode": props.package,
      "hash": hash,
      'eth_address': props?.user_wallet,
    }
    // console.log('data', data);

    let res = await _fetch(`${api_url}investment/invest-now`, "POST", data)
    // console.log('res', res);
    if (res?.status === 'success') {
      toasted.success(res?.message);
      props.onCloseModal5();
      setbtnDisable(false)
      setapproveLoader('3')
      // window.location.reload();
      // Navigate("/dashboard");
    }
    else {
      toasted.error(res?.message);
      setbtnDisable(false)
      setapproveLoader('3')
    }
  }


  const getSponserInfo = async () => {
    let res = await _fetch(`${api_url}user/sponserInfo/`, "GET", {})
    if (res?.status === 'success') {
      SetsponserAddress(res?.data?.wallet_address);
      // console.log('res?.data?.data sponser',res?.data.wallet_address);
    }
  }


  useEffect(() => {
    getSponserInfo();
  }, []);

  return (
    <>

      <div className='vista_sec'>
        <h5>Restake VISTA </h5>
        <p>Amount (BEP20-USDT)</p>
        <input type="number" placeholder='minimum 50 USDT' onChange={(e) => setAmount(e.target.value)} value={amount} />
        <p>Amount (VISTA)</p>
        <input type="number" placeholder='00000' value={(parseInt(amount) / props?.coinPrice)?.toFixed(3)} />
        <div className='stake_btn'>


          <button className='button_style' onClick={approveAndSendTokens} disabled={btnDisable}>
            {btnDisable === true &&
              approveLoader == 1 ? <LoaderDyn text={'Wating for approval'} /> : approveLoader == 2 ? <LoaderDyn text={'Wating for Confirmation'} /> : ''

            }
            {btnDisable === false && 'Restake VISTA '}
          </button>
          {/* <button onClick={() => {StakeAmount('sdf')}}>safdasd test</button> */}
        </div>
      </div>
    </>
  );
}

export default RestakeCoin;