import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import { Row, Col } from "react-bootstrap";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import EmailIcon from "@mui/icons-material/Email";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Table from "react-bootstrap/Table";
import ReplayIcon from "@mui/icons-material/Replay";
import _fetch from "../../config/api";
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";
import '../../Assets/Scss/tree.scss';
import userActive from "../../Assets/Image/userIconactive.png"
import userInactive from "../../Assets/Image/userIconinactive.png"
import { Link, useNavigate } from "react-router-dom";
import toasted from "../../config/toast";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Team = (props: any) => {
  useEffect(() => {
    document.title = `${props.pageTitle}`;
  });

  const [sponsorData, setSponsorData] = useState({ email: "", user_info: { name: "", package: { total_package: 0 } } });
  const [levelSearch, setLevelSearch] = useState('');
  const [typeSearch, setTypeSearch] = useState('');
  const [downloadID, setDownloadID] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [allUsersDetail, setAllUsersDetail] = useState([]);
  const [searchedType, setSearchType] = useState('level');
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allLevels, setAllLevels] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
  ]);

  const Navigate = useNavigate();

  const initialDirectTeamTree = {
    name: '',
    package: 0,
    username: '',
    direct_business: 0,
    rank: '',
    directs: [
      {
        directanotherDirects: []
      }
    ]
  };

  const [keyCount, setKeycount] = useState(0);
  const [directTeamTree, setDirectTeamTree] = useState(initialDirectTeamTree);

  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    getSponsorDetails();
    // getActivationDetails()
  }, []);

  useEffect(() => {
    getAllTeamData();
    getAllTeamTree();
  }, [levelSearch, downloadID, page, limit]);

  const getSponsorDetails = async () => {
    let data = await _fetch(`${api_url}sponsor/sponsordetails`, 'GET', {})

    if (data?.status === 'success') {
      setSponsorData(data?.data[0]);
    }
  }
  const getAllTeamData = async () => {
    setLoading(true);
    let searchData = '';

    if (levelSearch !== "" || downloadID !== "") {
      searchData += `&search=${searchedType}&value=${levelSearch}&downline_id=${downloadID}`;
    }

    if (typeSearch) {
      searchData += `&type=${typeSearch}`;
    }

    if (startDate !== '' || endDate !== '') {
      searchData += `&start_date=${startDate}&end_date=${endDate}`;
    }

    let data = await _fetch(`${api_url}sponsor/getteam?&page=${page}&limit=${limit}${searchData}`, 'GET', {})
    if (data?.status === 'success') {
      setLoading(false);
      setAllUsersDetail(data?.data);
      setTotalRecords(data?.total);
    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };

  const refresh = () => window.location.reload();

  const getAllTeamTree = async () => {
    setLoading(true);
    // if (levelSearch != "" || downloadID != "") {
    //   var searchData = `&search=${searchedType}&value=${levelSearch}&downline_id=${downloadID}`;
    // } else {
    //   var searchData = "";
    // }
    let data = await _fetch(`${api_url}network/direct-tree`, 'GET', {})

    if (data?.status === 'success') {
      setDirectTeamTree(data?.data?.[0]);
      setLoading(false);
    }
  }

  const handleSearch = async () => {

    if (searchQuery) {
      await findUserInTree(searchQuery);
    } else {
      console.log("User not found");
    }
  };

  const findUserInTree = async (username: any) => {
    Navigate(`/team/${username}`);
  }

  const copyUsername = async (username: any) => {
    navigator.clipboard.writeText(username);
    toasted.success('User ID copied to clipboard!');
  }

  return (
    <main>
      <div className="team-section">
        <div className="coustom_container">
          <div className="team-outer  loader_main">
            {loading &&
              <div className="spinner_bg">

                <div className="color_ring">  <ColorRing
                  visible={true}
                  height="80"
                  width="80"

                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                /></div>

              </div>
            }

            <Tabs>
              <Row>
                <Col xl={12} >
                  <div className="team-inner">
                    <Row className="team_row">
                      <Col xl={3} md={4} lg={4} className="p-0" >
                        <div className="team-sponser">
                          <div className="sponser-head">
                            <h6 className="mb-2">MY SPONSOR</h6>
                          </div>
                          <div className="sponser-content">
                            <p>
                              <AccountCircleRoundedIcon />{sponsorData?.user_info?.name?.toUpperCase()}
                            </p>
                            <p>
                              <EmailIcon />
                              {sponsorData?.email}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xl={9} md={8} lg={8} className="team_col p-0">
                        <div className="team-head-content">
                          <div className="team-search">
                            <form className="serch-form">
                              <input
                                type="text"
                                id="names"
                                placeholder="Search by username" onChange={(e: any) => setDownloadID(e.target.value)}

                              ></input>
                              <select className="form-control level" onChange={(e: any) => setLevelSearch(e.target.value)} value={levelSearch}>
                                <option value="">All</option>
                                {allLevels?.map((item: any, key: any) => (
                                  <option key={key} value={item}> {'Level' + " " + item} </option>
                                ))}
                              </select>
                            </form>
                            <div className="form-btn">
                              <button className="searchicons" onClick={refresh}>
                                <ReplayIcon />
                              </button>
                            </div>
                          </div>
                          <div className="team-tabs">
                            <TabList>
                              <Tab>TABLE VIEW</Tab>
                              <Tab>TREE VIEW</Tab>
                            </TabList>
                          </div>
                        </div>
                      </Col>

                      <Col xl={12} md={8} lg={8} className="team_col p-0">
                        <div className="team-head-content">
                          <div className="team-search">
                            <form className="serch-form">
                              <input
                                type="date"
                                id="names"
                                placeholder="Search by date"
                                onChange={(e: any) => setStartDate(e.target.value)}>
                              </input>

                              <input
                                type="date"
                                id="names"
                                placeholder="Search by date"
                                onChange={(e: any) => setEndDate(e.target.value)}>
                              </input>


                              <select className="form-control level" onChange={(e: any) => setTypeSearch(e.target.value)} value={typeSearch}>
                                <option value="">Select Option</option>
                                <option value='activate'>Stake Type</option>
                                <option value='upgrade'>Restake Type</option>
                              </select>
                              <button className="search-btn" type="button" onClick={getAllTeamData}>Search</button>

                            </form>
                          </div>
                        </div>
                      </Col>

                    </Row>
                    <div className="panel">
                      <TabPanel>
                        <div className="table_section">
                          <div className="scrolling_table">
                            <div className="scroll_under">
                              <Table className="support_table">
                                <thead>
                                  <tr className="table_heading_row">
                                    <th className="table_heading">#</th>
                                    <th className="table_heading">Username</th>
                                    <th className="table_heading">Name</th>
                                    <th className="table_heading">Total Package</th>
                                    <th className="table_heading">Level</th>
                                    <th className="table_heading">Rank</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {allUsersDetail?.map((item: any, index: any) => {

                                    return (
                                      <>
                                        <tr className="table_data_row">
                                          <td className="table_data">
                                            <div className="pic_text"> {page > 1 ? index + keyCount + 1 : index + 1} </div>
                                          </td>
                                          <td className="table_data">{item?.downlineId}</td>

                                          <td className="table_data">{item?.downlineDetails?.user_info?.name}</td>
                                          <td className="table_data">{item?.downlineDetails?.user_info?.package?.total_package}</td>
                                          <td className="table_data payment_fee"> {item?.levelNumber} </td>
                                          <td className="table_data payment_fee"> {item?.downlineDetails?.user_info?.rank} </td>
                                        </tr>

                                      </>
                                    )
                                  })}
                                </tbody>
                              </Table>

                              {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={1}
                                pageCount={totalRecords / 10}
                                previousLabel="<"
                              />}

                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card mt-5 main-tree">
                              <div className="card-body">
                                {/* <h6> Comming soon </h6> */}
                                <form className="serch-form d-flex gap-4">
                                  <input
                                    className="input-field"
                                    type="text"
                                    placeholder="Search by username"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value.toLocaleLowerCase())}
                                  />
                                  <button type="button" onClick={handleSearch}>Search</button>
                                </form>
                                <ul className="tree vertical ">
                                  <li>
                                    <div className="user-main-sec">
                                      <div className="user">
                                        <div className="user-img">
                                          <img className="img-sec" src={directTeamTree?.package ? directTeamTree?.package > 0 ? userActive : userInactive : ''} alt="userInactive" />
                                        </div>
                                        <p>  {directTeamTree?.name !== 'null null' ? directTeamTree?.name : 'No Name'} </p>
                                      </div>

                                      <div className="dropdown-sec">
                                        <p> User ID : <span> {directTeamTree?.username ? `${directTeamTree?.username?.substring(0, 4)}...${directTeamTree?.username?.substring(directTeamTree?.username?.length - 4)}` : ''} </span> </p>
                                        <p>  User Name : <span> {directTeamTree?.name !== 'null null' ? directTeamTree?.name : 'No Name'} </span> </p>
                                        <p>  Investment : <span> {directTeamTree?.package ? directTeamTree?.package : "0"}</span> </p>
                                        <p>  Total Business : <span> {directTeamTree?.direct_business ? directTeamTree?.direct_business : '0'} </span> </p>
                                        <p>  Rank : <span> {directTeamTree?.rank} </span> </p>
                                      </div>
                                    </div>

                                    <ul className="inner-team">
                                      {directTeamTree?.directs?.map((item: any, key: any) => {

                                        return (
                                          <>
                                            <li key={key} >
                                              <div className="user-main-sec">
                                                <div className="user">
                                                  <div className="user-img">
                                                    <img className="img-sec" src={item?.package > 0 ? userActive : userInactive} alt="userInactive" />
                                                  </div>
                                                  <p>  {item?.name !== 'null null' ? item?.name : 'No Name'} </p>
                                                </div>

                                                <div className="dropdown-sec">
                                                  <div className="d-flex align-items-center justify-content-center gap-3">
                                                    <Link to={`/team/${item?.referalUsername}`}> User ID : <span> {item?.referalUsername ? `${item?.referalUsername?.substring(0, 4)}...${item?.referalUsername?.substring(item?.referalUsername?.length - 4)}` : ""} </span> </Link>
                                                    {item?.referalUsername ?
                                                      <ContentCopyIcon style={{ fontSize: "18px", fontWeight: "600", color: "#24a4e3" }} onClick={() => copyUsername(item?.referalUsername)} />
                                                      : ""}
                                                  </div>
                                                  <p>   User Name : <span> {item?.name !== 'null null' ? item?.name : 'No Name'} </span> </p>
                                                  <p>  Investment : <span> {item?.package ? item?.package : "0"}</span> </p>
                                                  <p>  Total Business : <span> {item?.direct_business ? item?.direct_business : '0'} </span> </p>
                                                  <p>  Rank : <span> {item?.rank} </span> </p>
                                                </div>
                                              </div>
                                              <ul>
                                                {item?.directanotherDirects?.map((inneritem: any, index: any) => {
                                                  return (
                                                    <>
                                                      <li>
                                                        <div className="user-main-sec">
                                                          <div className="user">
                                                            <div className="user-img">
                                                              <img className="img-sec" src={inneritem?.package > 0 ? userActive : userInactive} alt="userInactive" />
                                                            </div>
                                                            <p> {inneritem?.name !== 'null null' ? inneritem?.name : 'No Name'} </p>
                                                          </div>

                                                          <div className="dropdown-sec">
                                                            <div className="d-flex align-items-center justify-content-center gap-3">
                                                              <Link className="m-0" to={`/team/${inneritem?.username}`}> User ID : <span> {inneritem?.username ? `${inneritem?.username?.substring(0, 4)}...${inneritem?.username?.substring(inneritem?.username?.length - 4)}` : ""} </span></Link>
                                                              {inneritem?.username ?
                                                                <ContentCopyIcon style={{ fontSize: "18px", fontWeight: "600", color: "#24a4e3" }} onClick={() => copyUsername(inneritem?.username)} />
                                                                : ''}
                                                            </div>
                                                            <p>   User Name : <span> {inneritem?.name !== 'null null' ? inneritem?.name : 'No Name'}</span> </p>
                                                            <p> Investment : <span> {inneritem?.package ? inneritem?.package : '0'}</span> </p>
                                                            <p>  Total Business : <span> {inneritem?.direct_business ? inneritem?.direct_business + inneritem?.team_business : '0'} </span> </p>
                                                            <p>  Rank : <span> {inneritem?.rank} </span> </p>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </>
                                                  )
                                                })}
                                              </ul>
                                            </li>
                                          </>
                                        )
                                      })}
                                    </ul>
                                  </li>
                                </ul>

                              </div>

                            </div>
                          </div>
                        </div>

                      </TabPanel>
                    </div>
                  </div>
                </Col>
              </Row>
            </Tabs>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Team;
